/* https://fonts.google.com/
   https://www.youtube.com/watch?v=arfDRUIZOiw&list=PL4cUxeGkcC9gpXORlEHjc5bgnIi5HEGhw&index=6
 */
@import url('https://fonts.googleapis.com/css2?family=Pattaya&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

@tailwind base;
/* tailwindcss forms plugins loaded in base layer - https://www.youtube.com/watch?v=pONeWAzDsQg */
@tailwind components;
@tailwind utilities;

@layer base {
  a.hyperlinkStyle {
    @apply text-blue-600 hover:text-blue-700 hover:underline
  }
}
